import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { HSRItem } from '../../../modules/hsr/common/components/hsr-item';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRGuidesEdiolonPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Eidolon for Trailblazer</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_shadows.png"
            alt="Eidolon for Trailblazer"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Eidolon for Trailblazer</h1>
          <h2>
            This guide will help you find the items needed to upgrade your
            Trailblazer Eidolon ranks.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="What are Eidolon?" />
        <p>
          Eidolons enhance Character’s abilities or provide them with new
          passive bonuses. Each Character has 6 Eidolon upgrades with each
          Eidolon upgrade providing a different bonus.
        </p>
        <p>
          While for the majority of characters the only way to get your hands on
          their Eidolon is to obtain their dupes (via Warps), there are a few
          exceptions with the main character - Trailblazer - being one of them.
          In case of the MC, instead of pulling his/her dupes you need to obtain
          some special items:
        </p>
        <ul className="hsr-item-list">
          <li>
            <HSRItem slug="shadow-of-destruction" mode="inline" enablePopover />{' '}
            - for Physical Trailblazer
          </li>
          <li>
            <HSRItem
              slug="shadow-of-preservation"
              mode="inline"
              enablePopover
            />{' '}
            - for Fire Trailblazer
          </li>
        </ul>
        <SectionHeader title="Shadow of Destruction" />
        <p>
          Here's a list of places where you can find all 6 Shadows of
          Destruction for your Physical Trailblazer:
        </p>
        <ol>
          <li>Herta Space Station - Prologue reward,</li>
          <li>Jarilo-VI - Main Story reward,</li>
          <li>Trailblazer Rank 19,</li>
          <li>Trailblazer Rank 30,</li>
          <li>Internal Purchase Officer on Herta Space Station,</li>
          <li>Underground Shop on Jarilo-VI.</li>
        </ol>
        <p>
          Overall, since all of those items are available quite early in the
          game, you will be able to max your Physical MC Eidolon within the
          first few days of playing.
        </p>
        <SectionHeader title="Shadow of Preservation" />
        <p>
          Here's a list of places where you can find all 6 Shadows of
          Destruction for your Fire Trailblazer:
        </p>
        <ol>
          <li>
            Jeweler's Pagoda on The Xianzhou Luofu (you can get 4 Eidolons from
            there).
          </li>
          <li>
            Main Story on The Xianzhou Luofu (you can get 2 Eidolons from
            there).
          </li>
        </ol>
        <p>Below's video shows the location of the shop:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="TYiMn8iDPZM" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesEdiolonPage;

export const Head: React.FC = () => (
  <Seo
    title="Eidolon for Trailblazer | Honkai: Star Rail | Prydwen Institute"
    description="This guide will help you find the items needed to upgrade your Trailblazer Eidolon ranks."
  />
);
